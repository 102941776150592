import React from 'react';
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import PheonixButtonProps from '../interfaces/PheonixButtonProps';

const PheonixButton: React.FC<PheonixButtonProps> = ({ label,disabled,onClick, ...props }) => {
  return (
    <MuiButton disabled={disabled} onClick={onClick} variant="outlined"  style={{ backgroundColor:disabled ? 'lightgray' : '#333843',color: disabled ? 'darkgray' :'white', padding: '5px', width: '210px', height: '36.5px',borderRadius:'4px',border:  '1px',boxSizing:'border-box'  }} {...props} >
      {label}
    </MuiButton>
  );
};
export default PheonixButton;