export const TEXT_MESSAGES = {
    LOG_BUTTON:"Sign in to Dasboard",
    EMAIL_REG: /^[a-zA-Z0-9._%+-]+@(?!.*\.\w{0,2}$)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  };

  export const ERROR_MESSAGES = {
    ENTER_BOTH: "Please enter both email and password",
    VALID_EMAIL: "Please enter a valid email address",
    VALID_PHONE: "Phone number must be exactly 10 digits",
    VALID_PASSWORD: "Please enter a valid password",
    PWD_LENGTH: "Password should be minimum of 8 characters",

   
  };