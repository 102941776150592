import React, { ChangeEvent, useState, useEffect, useContext } from "react";
import Typography from "@mui/material/Typography";
import PheonixPaper from "../Components/PheonixPaper";
import { useNavigate, useLocation } from 'react-router-dom';
import TextField from "@mui/material/TextField";
import PheonixTextField from "../Components/PheonixTextField";
import { TEXT_MESSAGES, ERROR_MESSAGES } from "../const";
import logo from "../assets/logo.png";
import Box from "@mui/material/Box";
import { api } from "../api";
import axios from 'axios';
import { LOGIN_USER  } from "../graphql/mutation";
import PheonixButton from "../Components/PheonixButton";
import { Stack } from "@mui/material";
import { Container, styled } from "@mui/material";
const Item = styled("div")({
  marginBottom: "15px",
  padding: "8px",
  textAlign: "center",
});

const Login: React.FC = () => {
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [pasErrorMessage, setPasErrorMessage] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const handleemailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setemail(e.target.value);
  };
  const handlepasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setpassword(e.target.value);
  };

  const handleEmailFocus = () => {
    setErrorMessage("");
  };
  const handleEmailBlur = () => {
    const emailRegex = TEXT_MESSAGES.EMAIL_REG;
    if (!emailRegex.test(email)) {
      setErrorMessage(ERROR_MESSAGES.VALID_EMAIL);
    } else {
      setErrorMessage("");
    }
  };
  const handlePasswordBlur = () => {
    const minLength = 8;
    const passlength = password.length >= minLength;
    if (password.length === 0) {
      setPasErrorMessage(ERROR_MESSAGES.VALID_PASSWORD);
    } else {
      setPasErrorMessage("");
    }
  };
  const handlePasswordFocus = () => {
    setPasErrorMessage("");
  };
  const handleLogin = async () => {
    try {
        const response = await axios.post(api.baseUrl, {
          query: LOGIN_USER,
          variables: {
            email: email,
            password: password
          },
        });
        const { success, message } = response.data.data.loginUser;
        if(success){
            navigate('/home')
        }

      } catch (error: any) {
        setError(error.message);
      }
  };

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <PheonixPaper
          elevation={3}
          style={{
            padding: "10px",
            height: "376px",
            marginLeft: "10px",
            width: "680PX",
            borderRadius: "12px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "40%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={logo}
              alt="Logo"
              style={{ maxWidth: "200px", maxHeight: "200px" }}
            />
          </Box>
          <Box
            sx={{
              width: "60%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: "20px",
              backgroundColor: "#00000014",
              borderTopRightRadius: "12px",
              borderBottomRightRadius: "12px",
            }}
          >
            <Box component="form">
              <Stack spacing={2} direction="column">
                <Item>
                  <PheonixTextField
                    id="outlined-error"
                    type="email"
                    value={email}
                    placeholder="Email"
                    label="Email"
                    variant="outlined"
                    onChange={handleemailChange}
                    onFocus={handleEmailFocus}
                    onBlur={handleEmailBlur}
                    error={Boolean(errorMessage)}
                    helperText={errorMessage || ""}
                    style={{ width: "350px" }}
                  />
                </Item>
                <Item>
                  <PheonixTextField
                    id="outlined-error"
                    type="password"
                    value={password}
                    placeholder="Password"
                    label="Password"
                    variant="outlined"
                    onChange={handlepasswordChange}
                    onFocus={handlePasswordFocus}
                    onBlur={handlePasswordBlur}
                    error={Boolean(pasErrorMessage)}
                    helperText={pasErrorMessage || ""}
                    style={{ width: "350px" }}
                    disabled={Boolean(errorMessage)}
                  />
                </Item>
                <Item>
                <PheonixButton label={TEXT_MESSAGES.LOG_BUTTON} onClick={handleLogin} sx={{textTransform:'none'}} />
                </Item>
              </Stack>
            </Box>
          </Box>
        </PheonixPaper>
      </Box>
    </div>
  );
};
export default Login;
