import React, { ChangeEvent, FocusEvent, ReactNode } from "react";
import TextField from "@mui/material/TextField";
import PheonixTextFieldProps from "../interfaces/PheonixTextFieldProps";

const PheonixTextField: React.FC<PheonixTextFieldProps> = ({
  id,
  type,
  value,
  placeholder,
  label,
  variant,
  disabled,
  onChange,
  name,
  onFocus,
  onBlur,
  endAdornment,
  style,
  required,
  multiline,
  rows,
  rememberMe,
  className,
  error,
  helperText,
}) => {
  return (
    <TextField
      id={id}
      type={type}
      value={value}
      name={name}
      placeholder={placeholder}
      label={label}
      variant={variant}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      error={error}
      helperText={helperText}
      InputProps={{
        endAdornment: endAdornment,
        className: className,
        style: style,
      }}
      disabled={disabled}
      multiline={multiline}
      rows={rows}
      required={required}
     
    />
  );
};
export default PheonixTextField;
